//Internal Imports
import ProjectData from "./ProjectData";

const Projects = ({ projectData }) => {
  return (
    <section id="projects-section">
      <h1>.projects()</h1>
      <ProjectData projectData={projectData} />
    </section>
  );
};

export default Projects;
