// Internal Imports
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <div id="contact-wrapper">
      <h1>.contact()</h1>
      <div className="row">
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
