// External Imports
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Internal Imports
import Landing from "../Pages/Landing"

const Root = () =>{


    return(
        <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Landing} />
        </Switch>
        </BrowserRouter>
    )
}


export default Root;