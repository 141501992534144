const ProjectCarousel = ({ projectData, index, current }) => {
  return (
    <div
      className={index === current ? " carousel active" : "carousel"}
      key={index}
    >
      <div>
        <h3>{projectData.title}</h3>
      </div>
      <div>
        {" "}
        <a href={projectData.websiteLink}>
          <img src={projectData.image} alt=""></img>
        </a>
      </div>
      <div>
        {" "}
        <a href={projectData.gitHubLink} alt="" className="view-code">
          <button className="view-code-btn">View Code</button>
        </a>
      </div>
      <div>
        <p>{projectData.description}</p>
      </div>
    </div>
  );
};

export default ProjectCarousel;
