// External Imports
import React from "react";

// Internal Imports
import Root from "../App/Root/index"

function App() {
  return (
    <Root />
  );
}

export default App;
