// External Imports
import Icon from "react-icons-wrapper";

const About = () => {
  return (
    <div id="about-wrapper">
      <div className="about">
        <h1>.about()</h1>
        <p>
          I am a software developer currently open for hire. My interests lie in
          web development and machine learning. I am currently enrolled at
          Georgia Tech remotely in their part-time master's in computer science
          program. When I am not working on computer science related work I love
          to play soccer, guitar, and sail.
        </p>
      </div>
      <div className="language-skill-section">
        <div className="language-section">
          <h2>const Languages = () =&#62; &#123;</h2>
          <div className="dev-icons-wrapper">return(</div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"DiHtml5"} size={"3rem"} /> HTML5
          </div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"DiCss3"} size={"3rem"} /> CSS3
          </div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"DiJavascript"} size={"3rem"} />
            JavaScript
          </div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"DiPython"} size={"3rem"} />
            Python
          </div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"DiJava"} size={"3rem"} />{" "}
            Java)&#125;
          </div>
        </div>
        <div className="skill-section">
          <h2>const Skills = () =&#62; &#123;</h2>
          <div className="dev-icons-wrapper">return(</div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"DiReact"} size={"3rem"} />
            React/Redux
          </div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"FaGitSquare"} size={"3rem"} />{" "}
            Git
          </div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"DiNpm"} size={"3rem"} /> NPM
          </div>
          <div className="dev-icons-wrapper">
            <Icon className="dev-icons" name={"FaYarn"} size={"3rem"} />{" "}
            Yarn)&#125;
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
