// External Imports
import emailjs from "emailjs-com";

const ContactForm = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_MAIL_SERVICE_ID,
        process.env.REACT_APP_MAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="container">
      <h3>.contactMe()</h3>
      <form onSubmit={sendEmail}>
        <input type="hidden" name="contact_number" />
        <input type="text" name="user_name" placeholder="Name..." />
        <input type="email" name="user_email" placeholder="Email..." />
        <textarea name="message" placeholder="Message..." />
        <div className="btn-wrapper">
          <input type="submit" value="Send" />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
