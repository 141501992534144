// External Import
import Icon from "react-icons-wrapper";
import { Link } from "react-scroll";

// Internal Imports
import { useState } from "react";

const Footer = () => {
  const [hover, setHover] = useState(false);

  const toggle = () => {
    setHover(!hover);
  };

  return (
    <div className="footer-wrapper">
      <div className="footer-left">
        <p>Open for work</p>
        <p>devin.levine1@gmail.com &#183; (985)-373-8486</p>
        <p>New Orleans, La</p>
      </div>
      <div className="footer-right">
        <a href="https://www.linkedin.com/in/devin-levine-45a048181/">
          <Icon
            className="dev-icons-footer"
            name="AiFillLinkedin"
            size="3rem"
          />
        </a>
        <a href="https://github.com/devinlevine1">
          <Icon className="dev-icons-footer" name="AiFillGithub" size="3rem" />
        </a>
        <Link
          to="navbar-wrapper"
          smooth={true}
          duration={1000}
          onMouseEnter={() => toggle()}
          onMouseLeave={() => toggle()}
        >
          {!hover ? (
            <Icon
              className="home-page-return"
              name="IoIosArrowUp"
              size="3rem"
              color="#eaeaea"
            />
          ) : (
            <Icon
              className="home-page-return"
              name="IoIosArrowUp"
              size="3rem"
              color="#000"
            />
          )}
        </Link>
      </div>
    </div>
  );
};

export default Footer;
