//External Imports
import { useState } from "react";
import { BsBoxArrowInLeft, BsBoxArrowInRight } from "react-icons/bs";

// Internal imports
import ProjectCarousel from "./ProjectCarousel";

const data = [
  {
    title: "R6 Stats Project",
    websiteLink: "http://www.r6-stats.com",
    image: "r6-stats-homepage.png",
    gitHubLink: "https://github.com/devinlevine1/r6-stats",
    description:
      "A site built with React to search stats and leaderboards for the videogame, Rainbow Six: Siege. It utilizes the R6 Stats API and firebase for authorization with certain features requiring a registered account.",
  },

  {
    title: "Movie Project",
    websiteLink: "https://movie-site-29416.web.app/",
    image: "movie-project.png",
    gitHubLink: "https://github.com/devinlevine1/movie-site",
    description:
      "A site built with React to search ratings and overviews for films. It utilizes the TMDB API to display the information scene. This website is responsive and mobile friendly. ",
  },

  {
    title: "Finance-Site Project",
    websiteLink: "https://finance-site-8c66a.firebaseapp.com/",
    image: "finance-site.png",
    gitHubLink: "https://github.com/devinlevine1/finance-static-site-repo",
    description:
      "A static finanical companies landing page. This is a fully responsive site that utilizes design techniques for a sleek modern look. Uses vanillaJS for effects.",
  },
];

const ProjectData = () => {
  const [current, setCurrent] = useState(0);
  const length = data.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  return (
    <div className="slider">
      <BsBoxArrowInLeft className="arrow-left" onClick={prevSlide} />
      <BsBoxArrowInRight className="arrow-right" onClick={nextSlide} />
      {data.map((projectData, index) => {
        return (
          <div>
            {console.log(current, index)}
            {index === current && (
              <ProjectCarousel
                projectData={projectData}
                index={index}
                current={current}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectData;
