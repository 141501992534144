// External imports
import { Link } from "react-scroll";
import Icon from "react-icons-wrapper";

// Internal Imports
import SideMenu from "../SideMenu";
import { useState } from "react";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return !open ? (
    <div id="navbar-wrapper" className="close">
      <div className="navbar-title close">
        <h1>.devinLevine()</h1>
      </div>
      <div className="side-menu open">
        <ul className="navbar-list close">
          <li>
            <Link to="projects-section" smooth={true} duration={1000}>
              .projects()
            </Link>
          </li>
          <li>
            <Link to="about-wrapper" smooth={true} duration={1000}>
              .about()
            </Link>
          </li>
          <li>
            <Link to="contact-wrapper" smooth={true} duration={1000}>
              .contact()
            </Link>
          </li>
        </ul>
        <div className="navbar-btn-wrapper open">
          <button className="navbar-btn" onClick={() => toggle()}>
            <Icon name="FaBars" color="#eaeaea" size="1.5rem" />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div id="navbar-wrapper" className="open">
      <div className="navbar-title open">
        <h1>.devinLevine()</h1>
      </div>
      <SideMenu toggle={toggle} />
    </div>
  );
};

export default Navbar;
