// External Imports
import { Link } from "react-scroll";
import Icon from "react-icons-wrapper";

const SideMenu = ({ toggle }) => {
  return (
    <div className="side-menu close">
      <ul className="navbar-list open">
        <button className="navbar-btn-close" onClick={toggle}>
          <Icon name="AiOutlineClose" color="#eaeaea" size="1.5rem" />
        </button>
        <li>
          <Link to="projects-section" smooth={true} duration={1000}>
            .projects()
          </Link>
        </li>
        <li>
          <Link to="about-wrapper" smooth={true} duration={1000}>
            .about()
          </Link>
        </li>
        <li>
          <Link to="contact-wrapper" smooth={true} duration={1000}>
            .contact()
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
