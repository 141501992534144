const HeroSection = () => {
  return (
    <div className="hero-wrapper">
      <div className="hero-col">
        <h1>
          Software Developer Based In New Orleans, LA. Working With ReactJS.
        </h1>
      </div>
    </div>
  );
};

export default HeroSection;
