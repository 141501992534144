// Internal Imports
import Navbar from "../Components/Navbar";
import HeroSection from "../Components/HeroSection";
import Projects from "../Components/Projects";
import About from "../Components/About";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";

const Landing = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <Projects />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default Landing;
